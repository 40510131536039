<template>
	<div class="web-box">
		<div class="blocx">
			<div class="title">用户协议</div>
			<div id="editor1-toolbar-container"></div>
			<div id="editor1"></div>
		</div>
		<div class="blocx">
			<div class="title">隐私协议</div>
			<div id="editor2-toolbar-container"></div>
			<div id="editor2"></div>
		</div>
		<div class="flex-box">
			<a-button type="primary" :loading="subing" @click="submit">提交</a-button>
		</div>
	</div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn'
import MyUploadAdapter from "../../utils/uploader";
import { toolbar, fontSize } from '../../utils/toolbar'
export default {
	data() {
		return {
			editor1: null,
			editor2: null,
			editor1Data: '',
			editor2Data: '',
			subing: false,
			content: '',
			content1: '',
		}
	},
	computed: {

	},
	watch: {
		editor1Data(val) {
			this.content = val
		},
		editor2Data(val) {
			this.content1 = val
		}
	},
	created() {
		this.getInfo()
	},
	mounted() {
		let t = this
		t.initCKEditor('editor1')
		t.initCKEditor('editor2')
	},
	methods: {
		myUploadImagePlugin(ele) {
			let t = this
			t[ele].plugins.get("FileRepository").createUploadAdapter = loader => {
				return new MyUploadAdapter(loader);
			};
			t[ele].model.document.on('change:data', function () {
				t[ele + 'Data'] = t[ele].getData()
			});
		},
		initCKEditor(ele) {
			let t = this
			CKEditor.create(document.querySelector('#' + ele), {
				toolbar: toolbar,
				fontSize: fontSize,
				language: 'zh-cn',
				ckfinder: {
					uploadUrl: '/admin/Upload/uploadUrl'
					//后端处理上传逻辑返回json数据,包括uploaded(选项true/false)和url两个字段
				},
			}).then(editor => {
				const toolbarContainer = document.querySelector('#' + ele + '-toolbar-container');
				console.log(toolbarContainer)
				toolbarContainer.appendChild(editor.ui.view.toolbar.element);
				this[ele] = editor //将编辑器保存起来，用来随时获取编辑器中的内容等，执行一些操作
				t.myUploadImagePlugin(ele)
				console.log(Array.from(this[ele].ui.componentFactory.names()))
			}).catch(error => {
				console.error(error);
			});
		},
		getInfo() {
			let t = this
			t.$post('privacyServiceInfo').then(res => {
				let { code, data, msg } = res
				if (code == 0) {
					t.content = data.content
					t.content1 = data.content1
					t.editor1.setData(data.content)
					t.editor2.setData(data.content1)
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {

			})
		},
		submit() {
			let t = this
			t.subing = true
			t.$post('privacyService', {
				content: t.content,
				content1: t.content1
			}).then(res => {
				t.subing = false
				let { code, data, msg } = res
				if (code == 0) {
					t.$message.success(msg, 1.5)
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {
				t.subing = false
			})
		}
	}
}
</script>

<style lang="less">
.blocx {
	margin-bottom: 20px;
	.quill-editor-container {
		.ql-container {
			height: 400px;
		}
	}
}
.title {
	font-size: 18px;
	font-weight: bold;
	line-height: 42px;
	margin-bottom: 10px;
}
.hidden-upload {
	position: fixed;
	left: 0;
	top: 0;
	z-index: -1;
	opacity: 0;
	pointer-events: none;
}
</style>
